"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrontEndLoggerService = void 0;
const Interfaces_1 = require("../Interfaces");
var FrontEndLoggerService;
(function (FrontEndLoggerService) {
  /**
   * @title YOL Service
   * @version 1.0.58
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL FRONTEND LOGGER MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name SubmitLog
        * @summary This function write logs in kibana
        * @request POST:/frontend-logger/submit-log
        * @response `204` `void` No content
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        submitLog: (data, params = {}) => this.http.request("POST", `${this.url}/frontend-logger/submit-log`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  FrontEndLoggerService.Api = Api;
})(FrontEndLoggerService = exports.FrontEndLoggerService || (exports.FrontEndLoggerService = {}));
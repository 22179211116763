lib-svg {
  svg {
    width: 1rem;
    height: 1rem;
  }

  &.small svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  &.normal svg {
    width: 1rem;
    height: 1rem;
  }

  &.normal-plus svg {
    width: 1.5rem;
    height: 1.5em;
  }

  &.medium svg {
    width: 2rem;
    height: 2rem;
  }

  &.medium-responsive svg {
    width: 3rem;
    height: 3rem;

    @media (min-width: 1024px) {
      width: 4rem;
      height: 4rem;
    }
  }

  &.large svg {
    width: 4rem;
    height: 4rem;
  }

  &.extra-large svg {
    width: 5rem;
    height: 3.5rem;
  }

  &.larger svg {
    width: 6rem;
    height: 6rem;
  }

  &.size-10 svg {
    width: 10rem;
    height: 10rem;
  }

  &.size-8 svg {
    width: 8rem;
    height: 8rem;
  }

  &.size-full-width svg {
    width: 100%;
    height: 100%;
  }

  &.white svg,
  &.white svg path {
    fill: var(--basic-white);
  }

  &.color-nav svg,
  &.color-nav svg path {
    fill: var(--color-nav);
  }

  &.black svg,
  &.black svg path {
    fill: var(--color-black);
  }

  &.gray-2 svg,
  &.gray-2 svg path {
    fill: var(--color-gray-2);
  }

  &.gray-2 svg,
  &.primary svg path {
    fill: var(--color-primary) !important;
  }

  &.blue svg,
  &.blue svg path {
    fill: var(--color-blue-darkened);
  }
}

import { LinkField } from '@prismicio/client';

const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const urlStartsWithLanguage = (string: string): boolean =>
  string.substring(0, 4) === '/en/' ||
  string.substring(0, 4) === '/de/' ||
  string.substring(0, 4) === '/it/' ||
  string.substring(0, 4) === '/fr/';

export const createLinkFromString = (url: string) => {
  let link;
  if (url) {
    if (isValidHttpUrl(url)) {
      link = {
        url: url,
        link_type: 'Web',
      };
    } else {
      if (urlStartsWithLanguage(url)) {
        link = {
          slug: url
            .replace(/\/en\//, '')
            .replace(/\/it\//, '')
            .replace(/\/fr\//, '')
            .replace(/\/de\//, ''),
          link_type: 'Document',
        };
      } else {
        link = {
          slug: url.replace(/\//, ''),
          link_type: 'Document',
        };
      }
    }
  }

  return link as LinkField;
};

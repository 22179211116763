import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { from, of, switchMap } from 'rxjs';
import { RedirectService } from 'redirect';
import { CmsService } from '../cms.service';

interface PrismicPreviewGuardConfig {
  homeUID?: string; // Optional home UID for apps with a home document (i.e. website)
  resolveBy: 'id' | 'uid'; // Determines whether to resolve links using `doc.id` or `doc.uid`
}

export const prismicPreviewGuard =
  (config: PrismicPreviewGuardConfig): CanActivateFn =>
  route => {
    const platformId = inject(PLATFORM_ID);
    const redirectService = inject(RedirectService);
    const cmsService = inject(CmsService);
    if (isPlatformServer(platformId)) return of(true);

    const previewToken = route.queryParams['token'];
    const documentID = route.queryParams['documentId'];
    return from(
      cmsService.client.resolvePreviewURL({
        documentID,
        previewToken,
        defaultURL: '/',
        linkResolver: doc => {
          const path = config.homeUID && config.homeUID === doc[config.resolveBy] ? '' : doc[config.resolveBy];
          return `/${cmsService.getLanguageFromLocale(doc.lang)}/${path}`;
        },
      })
    ).pipe(switchMap(url => redirectService.redirectTo(url)));
  };

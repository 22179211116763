import { inject } from '@angular/core';
import { Route, ɵEmptyOutletComponent } from '@angular/router';
import { featureFlagResolver } from 'feature-flag';
import { FooterResolver } from 'footer';
import { BaseLangGuard, langUrlMatcher } from 'language';
import { MainNavigationResolver } from 'navigation';
import { prismicPreviewGuard } from 'prismic';
import { articleIdRedirect } from './article-page/article-id-redirect.guard';
import { ArticlePageComponent } from './article-page/article-page.component';
import { articleResolver } from './article-page/article.resolver';
import { isArticleGuard } from './article-page/is-article.guard';
import { zendeskArticleRedirect } from './article-page/zendesk-article-redirect.guard';
import { BaseComponent } from './base/base.component';
import { categoryIdRedirect } from './category-page/category-id-redirect.guard';
import { CategoryPageComponent } from './category-page/category-page.component';
import { categoryResolver } from './category-page/category.resolver';
import { isCategoryGuard } from './category-page/is-category.guard';
import { zendeskCategoryRedirect } from './category-page/zendesk-category-redirect.guard';
import { CategoryService } from './category.service';
import { HomePageComponent } from './home-page/home-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const legacyZendeskRedirects: Route[] = [
  {
    path: 'hc/:lang/articles/:article',
    canActivate: [zendeskArticleRedirect],
    component: ɵEmptyOutletComponent,
  },
  {
    path: 'hc/:lang/categories/:category',
    canActivate: [zendeskCategoryRedirect],
    component: ɵEmptyOutletComponent,
  },
  {
    path: 'hc/:lang',
    redirectTo: '',
    component: ɵEmptyOutletComponent,
  },
];

export const appRoutes: Route[] = [
  ...legacyZendeskRedirects,
  {
    path: 'prismic-preview',
    component: ɵEmptyOutletComponent,
    canActivate: [prismicPreviewGuard({ homeUID: '', resolveBy: 'id' })],
  },
  {
    matcher: langUrlMatcher,
    component: BaseComponent,
    resolve: { mainNavigation: MainNavigationResolver, footer: FooterResolver, featureFlags: featureFlagResolver },
    children: [
      {
        path: '',
        component: HomePageComponent,
        resolve: {
          categories: () => inject(CategoryService).getCategories(),
        },
      },
      {
        path: ':article',
        canMatch: [isArticleGuard],
        component: ArticlePageComponent,
        canActivate: [articleIdRedirect],
        resolve: {
          article: articleResolver,
        },
      },
      {
        path: ':category',
        canMatch: [isCategoryGuard],
        component: CategoryPageComponent,
        canActivate: [categoryIdRedirect],
        resolve: {
          category: categoryResolver,
        },
      },
      {
        path: ':error',
        component: NotFoundPageComponent,
      },
    ],
  },
  {
    path: '**',
    component: ɵEmptyOutletComponent,
    canActivate: [BaseLangGuard],
  },
];

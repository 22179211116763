<div class="sticky top-0 left-0 z-50 h-16 w-full kitsune-nav-bar">
  <div
    class="absolute w-full h-full px-3 xl:px-4 flex kitsune-nav-bar__inner"
    [ngClass]="{
      'active bg-color-dark': open,
      'kitsune-nav-bar--active': !open && scrolled,
      'kitsune-nav-gradient': !open && !scrolled,
    }"
  >
    <div class="flex flex-nowrap justify-between items-center xl:container w-full">
      <div class="flex flex-1 h-full">
        <lib-link
          [link]="{ link_type: LinkType.Web, url: logoUrl() }"
          [title]="['logoLink'] | translate"
          [ngClass]="{ 'mr-[1rem]': !!logo }"
        >
          @if (logo) {
            <div class="h-full">
              <img [src]="logo.url" [alt]="logo.alt" class="w-full h-full" />
            </div>
          } @else {
            <lib-svg
              svg="brand/logo"
              [ngClass]="{ 'logo-lebara ': brand.isLebara(), 'logo white': brand.isYallo() }"
            ></lib-svg>
          }
        </lib-link>
        <a href="#" class="text-white sr-only focus:not-sr-only" (click)="skipNav($event)">
          {{ ['skipNav'] | translate }}
        </a>
      </div>

      <!-- START: Desktop navbar -->
      <div #navbar class="kitsune-nav-bar__main mr-4 justify-center items-center hidden xl:flex gap-8" id="navbar">
        @for (item of menuItems; track item; let i = $index) {
          @if (isMenuItemWithDropdown(item); as itemWithDropdown) {
            <lib-menu
              class="kitsune-nav-menu"
              [ngClass]="{
                'kitsune-nav-menu--active': isDropdownActive(itemWithDropdown),
                'kitsune-nav-menu--lebara': !brand.isYallo(),
              }"
              [label]="itemWithDropdown.title"
            >
              @for (subItem of itemWithDropdown.secondLevelEntries; track subItem) {
                <lib-menu-item [menuItemLink]="getSubItemLink(subItem)">
                  <span class="font-bold" (click)="onSubItemClick(item, subItem)">{{ subItem.title }}</span>
                </lib-menu-item>
              }
            </lib-menu>
          }
          @if (isSimpleMenuItem(item); as simpleItem) {
            <lib-link
              [link]="simpleItem.link"
              class="kitsune-nav-bar__menu-item"
              [ngClass]="{
                'kitsune-nav-bar__menu-item--active': isSimpleItemActive(simpleItem),
              }"
              (onClick)="onSubItemClick(simpleItem, null)"
            >
              <span
                class="text-color-nav"
                [ngClass]="{ '2xl:text-xl font-bold': brand.isYallo(), 'xl:text-xl font-semibold': !brand.isYallo() }"
              >
                {{ simpleItem.title }}
              </span>
            </lib-link>
          }
        }
      </div>
      <!-- END: Desktop navbar -->

      <div class="flex flex-1 justify-end gap-1 xxs:gap-2 xs:gap-4">
        @if (phone?.phoneNumberLink && phone?.phoneNumber) {
          <div
            class="round-box h-12 w-12 sm:w-auto sm:whitespace-nowrap sm:px-3 flex justify-center items-center"
            [ngClass]="{ 'w-12': !phone?.phoneCallPrice, 'w-auto px-1 xxs:px-2': phone?.phoneCallPrice }"
          >
            <lib-link [link]="phone.phoneNumberLink" class="flex items-center" [ariaLabel]="ariaLabel">
              <div class="flex items-center justify-evenly">
                <lib-svg class="white normal-plus sm:mr-1" svg="fontawesome/phone"></lib-svg>
                <div>
                  <p class="hidden sm:block text-white font-bold text-sm xl:text-base">{{ phone.phoneNumber }}</p>
                  @if (phone?.phoneCallPrice) {
                    <p class="text-white text-xs xl:text-sm text-center">{{ phone.phoneCallPrice }}</p>
                  }
                </div>
              </div>
            </lib-link>
          </div>
        }
        <lib-language-selector [collapseOnMobile]="true"></lib-language-selector>
        <lib-dropdown-menu [collapseOnMobile]="true"></lib-dropdown-menu>
        <button
          data-testid="nav-mobile-hamburger-menu"
          (click)="toggleNav()"
          class="round-box h-12 w-12 flex justify-center items-center xl:hidden"
        >
          <lib-svg class="white medium" [svg]="open ? 'nav-close' : 'nav-burger'"></lib-svg>
        </button>
      </div>
    </div>

    @if (open) {
      <div #navbar class="flex flex-col justify-center align-items xl:hidden">
        @for (item of menuItems; track item; let i = $index) {
          <div class="my-4 flex justify-center text-color-nav text-center">
            @if (isMenuItemWithDropdown(item); as itemWithDropdown) {
              <div class="flex flex-col justify-center items-center">
                <button
                  (click)="selectDropdown(i, itemWithDropdown)"
                  class="kitsune-menu-mobile flex justify-center items-center"
                  [attr.aria-expanded]="isDropdownSelected(i)"
                  [attr.data-testid]="'nav-menu-' + generateTestIdName(itemWithDropdown.title)"
                >
                  <p
                    class="inline-block text-2xl font-bold text-center"
                    [ngClass]="{ 'kitsune-nav-bar__menu-item--active': isDropdownActive(itemWithDropdown) }"
                  >
                    {{ itemWithDropdown.title }}
                  </p>
                  <lib-svg
                    svg="fontawesome/chevron-down"
                    class="kitsune-menu-mobile__arrow ml-2 fill-white transition-transform duration-200"
                  ></lib-svg>
                </button>
                <ul [ngClass]="{ hidden: selectedDropdown !== i }" class="flex flex-col items-center">
                  @if (itemWithDropdown.product) {
                    <div>
                      <div class="flex justify-center mt-2">
                        <lib-product-box
                          class="w-[384px]"
                          [productItem]="itemWithDropdown.product"
                          [lightMode]="true"
                          [isNavProduct]="true"
                        >
                        </lib-product-box>
                      </div>
                    </div>
                  }
                  @for (subItem of itemWithDropdown.secondLevelEntries; track subItem) {
                    <li class="mt-3">
                      <lib-link
                        [attr.data-testid]="'nav-submenu-' + generateTestIdName(subItem.title)"
                        [link]="subItem.link"
                        (onClick)="onSubItemClick(itemWithDropdown, subItem)"
                      >
                        {{ subItem.title }}
                      </lib-link>
                    </li>
                  }
                </ul>
              </div>
            }
            @if (isSimpleMenuItem(item); as simpleItem) {
              <div>
                <lib-link [link]="simpleItem.link" (onClick)="onSubItemClick(simpleItem, null)">
                  <p
                    class="text-2xl font-bold flex justify-center items-center"
                    [ngClass]="{ 'kitsune-nav-bar__menu-item--active': isSimpleItemActive(simpleItem) }"
                  >
                    {{ simpleItem.title }}
                  </p>
                </lib-link>
              </div>
            }
          </div>
        }
      </div>
      <div class="flex justify-center px-8 pb-24">
        @for (lang of languageService.langs; track lang) {
          <p
            class="text-base font-bold text-color-nav mx-2"
            [ngClass]="{ 'kitsune-nav-bar__menu-item--active': lang === languageService.current }"
          >
            <lib-link [link]="getLink(lang)" queryParamsHandling="preserve" [translate]="lang"></lib-link>
          </p>
        }
      </div>
    }
  </div>
</div>

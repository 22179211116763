"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LineCheckService = void 0;
const Interfaces_1 = require("../Interfaces");
var LineCheckService;
(function (LineCheckService) {
  /**
   * @title YOL Service
   * @version 1.0.135
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL LINE CHECK MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name LineCheckByAddress
        * @summary Line Check by address
        * @request POST:/linecheck/address
        * @response `200` `LineCheckResp` Ok
        * @response `404` `{
        error: "ERR_ACCESS_PROVIDER_NOT_AVAILABLE" | "ERR_PRODUCTS_NOT_AVAILABLE",
             }`
        * @response `500` `{
        error: "ERR_FROM_PRODUCT_CATALOG_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        lineCheckByAddress: (data, query, params = {}) => this.http.request("POST", `${this.url}/linecheck/address`, {
          params: query,
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name LineCheckByOtoId
        * @summary Line Check by OTOID
        * @request POST:/linecheck/otoid
        * @response `200` `LineCheckResp` Ok
        * @response `404` `{
        error: "ERR_ACCESS_PROVIDER_NOT_AVAILABLE" | "ERR_PRODUCTS_NOT_AVAILABLE" | "ERR_OTOID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_PRODUCT_CATALOG_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        lineCheckByOtoId: (data, query, params = {}) => this.http.request("POST", `${this.url}/linecheck/otoid`, {
          params: query,
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name PerformTechnicalLineCheck
        * @summary Line Check by address or OTOID (Legacy)
        * @request POST:/linecheck/performTechnicalLineCheck
        * @response `200` `TechnicalLineCheckResp` Ok
        * @response `400` `{
        error: "ERR_BAD_REQUEST",
             }`
        * @response `404` `{
        error: "ERR_ACCESS_PROVIDER_NOT_AVAILABLE",
             }`
        * @response `500` `{
        error: "ERR_FROM_PRODUCT_CATALOG_API" | "ERR_SYSTEM_ERROR",
             }`
        */
        performTechnicalLineCheck: (data, params = {}) => this.http.request("POST", `${this.url}/linecheck/performTechnicalLineCheck`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  LineCheckService.Api = Api;
})(LineCheckService = exports.LineCheckService || (exports.LineCheckService = {}));
"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SmartwatchService = void 0;
const Interfaces_1 = require("../Interfaces");
var SmartwatchService;
(function (SmartwatchService) {
  /**
   * @title YOL Service
   * @version 0.1.11
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL SMART WATCH MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @name StartAppleWebsheet
        * @request POST:/smartwatch/ws/start-apple
        * @response `302` `void` Redirect
        * @response `401` `{
        error: "ERR_NOT_ALLOWED",
             }`
        * @response `404` `{
        error: "ERR_CONTEXT_ID_NOT_FOUND" | "ERR_SUBSCRIPTION_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_ESI_API" | "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        startAppleWebsheet: (data, params = {}) => this.http.request("POST", `${this.url}/smartwatch/ws/start-apple`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @name StartSamsungWebsheet
        * @request POST:/smartwatch/ws/start-samsung
        * @response `204` `void` No content
        * @response `401` `{
        error: "ERR_NOT_ALLOWED",
             }`
        * @response `404` `{
        error: "ERR_CONTEXT_ID_NOT_FOUND" | "ERR_SUBSCRIPTION_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_ESI_API" | "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        startSamsungWebsheet: (data, params = {}) => this.http.request("POST", `${this.url}/smartwatch/ws/start-samsung`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name ActivateSlaveRateplan
        * @request POST:/smartwatch/ws/activate-slave
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_SLAVE_SUBS_MAX_REACHED" | "ERR_WRONG_PRODUCT_CLASS" | "ERR_WRONG_PRODUCT" | "ERR_WRONG_PROMOTION",
             }`
        * @response `401` `{
        error: "ERR_SUBSCRIPTION_NOT_FOUND",
             }`
        * @response `404` `{
        error: "ERR_PRODUCT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERROR_FROM_PRODUCT_CATALOG_API" | "ERROR_FROM_API" | "ERROR_SUBMITTING_ORDER_TO_RATOR",
             }`
        */
        activateSlaveRateplan: (data, params = {}) => this.http.request("POST", `${this.url}/smartwatch/ws/activate-slave`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.subscription = {
        /**
        * No description
        *
        * @tags Subscription
        * @name GetWsStatus
        * @request GET:/smartwatch/ws/status
        * @response `200` `{
        status: StatusEnum,
             }` Ok
        * @response `401` `{
        error: "ERR_SUBSCRIPTION_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getWsStatus: (query, params = {}) => this.http.request("GET", `${this.url}/smartwatch/ws/status`, {
          params: query,
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  SmartwatchService.Api = Api;
})(SmartwatchService = exports.SmartwatchService || (exports.SmartwatchService = {}));
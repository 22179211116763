.curved-box {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 4rem;
  min-height: 100%;

  &.bg-linear {
    background-repeat: no-repeat;
    background-position: top;
    background-image: var(--color-primary-default);

    &-50 {
      background-size: calc(100%) calc(50%);
    }
    &-75 {
      background-size: calc(100%) calc(75%);
    }
  }

  .content {
    @apply z-10 relative;
  }
  color: white;

  > lib-svg {
    svg {
      z-index: 0;
      display: block;
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      top: auto;
    }

    &:last-child {
      svg {
        top: 0;
        bottom: auto;
      }
    }
  }
}

import { Content, LinkField } from '@prismicio/client';
import { ProductCatalogService } from '@yol-digital/ms-client';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { convertProductBoxTable, PrismicProduct, prismicToHtmlString, prismicToImage } from 'prismic';
import { urlify } from 'utils';
import CatalogResponse = ProductCatalogService.CatalogResponse;

export type ProductCombined = PrismicProduct & PCProduct;

export class PCProduct extends PrismicProduct {
  // static helper method to create Products from a RatePlan
  static fromMS(rateplan: CatalogResponse, prismicData?: Content.ProductBoxDocumentData): PCProduct {
    const p = new this();

    p.id = rateplan.id;
    p.product_code = rateplan.code;
    p.source = rateplan.source;
    p.name = prismicData?.display_name ? prismicData?.display_name : rateplan.name.replace(/yallo|lebara/g, '');
    p.url = urlify(rateplan.displayName);
    p.monthly_cost = rateplan.basePrice;
    p.activation_fee = rateplan.activationFee;
    p.rateplan_id = rateplan.id;
    p.lockin_length = rateplan.availableLockings;
    p.defaultPromotion = rateplan.defaultPromotion;
    p.productSpecFamily = rateplan.productSpecFamily;
    p.product_type = prismicData?.product_type?.toLocaleLowerCase() ?? rateplan.productSpecFamily.toLocaleLowerCase();
    p.productSpecClass = rateplan.productSpecClass;
    p.isSlave = rateplan.isSlave;

    //Get values from Prismic
    if (prismicData) {
      p.short_description = prismicToHtmlString(prismicData.short_description);
      if (prismicData.product_icon?.url) {
        p.product_icon = prismicToImage(prismicData.product_icon);
      }
      p.productSpecSubCategory = prismicData.product_spec_sub_category;
      p.product_details_link_label = prismicData.product_details_link_label;
      p.product_details_link = prismicData.product_details_link as LinkField;
      p.cta_button_label = prismicData.cta_button_label;
      p.alternative_link = prismicData.alternative_link;
      p.promotion_banner_text = prismicData.promotion_banner_text;
      if (prismicData.body && prismicData.body[0]) {
        p.prismicTable = convertProductBoxTable(prismicData.body[0]);
      }
    }

    p.type = p.productSpecClass === 'PREPAID' ? 'prepaid' : 'postpaid';

    p.setAnalyticsCategories(rateplan.productSpecClass, rateplan.productSpecFamily);

    return p;
  }
}

import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Client, createClient, PrismicDocument, filter as prismicFilter } from '@prismicio/client';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { LanguageService } from 'language';
import { SUPPORTED_LANGUAGES, TransferStateService } from 'utils';
import { PRISMIC_ENDPOINT } from './prismic.token';
import { AllDocumentTypes } from './types.generated.interface';

interface Languages {
  de: string;
  en: string;
  fr: string;
  it: string;
}

interface LanguageUIDS {
  [key: string]: Languages | object | string;
}

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  private lang = inject(LanguageService);
  private transferState = inject(TransferStateService);
  private platformId = inject(PLATFORM_ID);
  private prismicEndpoint = inject(PRISMIC_ENDPOINT);
  private cookieService = inject(CookieService);
  languages = SUPPORTED_LANGUAGES;
  client: Client;
  private clientInitCompleteSubject = new BehaviorSubject<boolean>(false);
  public clientInitComplete$ = this.clientInitCompleteSubject.asObservable().pipe(
    filter(initComplete => initComplete),
    shareReplay(1)
  );
  alternateLanguageUIDs: LanguageUIDS = {};

  async init() {
    if (isPlatformServer(this.platformId)) {
      this.client = createClient<AllDocumentTypes>(this.prismicEndpoint, { fetch });
    } else {
      this.client = createClient<AllDocumentTypes>(this.prismicEndpoint);
    }
    this.clientInitCompleteSubject.next(true);
  }

  private async checkClientAvailable() {
    if (!this.client) await firstValueFrom(this.clientInitComplete$);
  }

  getLanguageFromLocale = (locale: string) => Object.entries(this.languages).find(([, value]) => value === locale)?.[0];

  async getByMultipleTypes(tag: string) {
    await this.checkClientAvailable();

    const locale = this.languages[this.lang.current];
    const documents = await this.client.getAllByTag(tag, { lang: locale });

    return documents;
  }

  /**
   * Gets a prismic document based on its type and unique identifier
   * @param type The type of document to search for
   * @param uid The unique identifier of the documnt
   */
  async getByUid(type: string, uid: string): Promise<PrismicDocument<Record<string, unknown>, string, string>> {
    await this.checkClientAvailable();
    const locale = this.languages[this.lang.current];
    const transferKey = locale + '_' + type + '_' + uid + this.getTransferKeyExtra();
    const result = await this.transferState.withStateTransfer(transferKey, async () => {
      try {
        return await this.client.getByUID(type, uid, { lang: locale });
      } catch {
        return undefined;
      }
    });

    if (type == 'new_landing_page') {
      this.alternateLanguageUIDs[uid] = {};
      result?.alternate_languages.forEach(alternate => {
        const lang = this.getLanguageFromLocale(alternate.lang);
        (this.alternateLanguageUIDs[uid] as LanguageUIDS)[lang] = alternate.uid;
      });
    }
    return result;
  }

  /**
   * Gets a prismic document based on its ID
   * @param id The ID of the document to fetch (not the UID)
   * @returns A prismic document. If it doesn't exist it will throw an exception
   */
  async getById(id: string, lang = this.languages[this.lang.current]): Promise<PrismicDocument> {
    await this.checkClientAvailable();
    const transferKey = lang + '_' + id + this.getTransferKeyExtra();
    return await this.transferState.withStateTransfer(transferKey, () => this.client.getByID(id, { lang }));
  }

  async getAllByTag(tag: string): Promise<PrismicDocument[]> {
    await this.checkClientAvailable();
    const lang = this.languages[this.lang.current];
    const transferKey = `${lang}_${tag}` + this.getTransferKeyExtra();

    return await this.transferState.withStateTransfer(transferKey, async () => {
      return await this.client.getAllByTag(tag, { lang });
    });
  }

  /**
   * Gets all documents from prismic for a certain type
   * @param type The type of document to get
   * @param enOnly True for document types that are not language specific
   * @param page Dont use, this is for internal recursive use
   * @param oldResults Dont use, this is for internal recursive use
   */
  async getByType(
    type: string,
    filters: string[] = [],
    enOnly = false,
    page = 1,
    fetchLinks: string[] = [],
    oldResults: PrismicDocument[] = []
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    const lang = enOnly ? 'en' : this.languages[this.lang.current];
    const filtersKey = filters.length ? `_${filters.join('_')}` : '';
    const fetchLinksKey = fetchLinks.length ? `_${fetchLinks.join('_')}` : '';
    const transferKey = `${lang}_${type}${filtersKey}${fetchLinksKey}` + this.getTransferKeyExtra();

    return await this.transferState.withStateTransfer(transferKey, () =>
      this.getByTypeRec(type, filters, lang, page, fetchLinks, oldResults)
    );
  }

  private async getByTypeRec(
    type: string,
    filters: string[],
    lang: string,
    page: number,
    fetchLinks: string[],
    oldResults: PrismicDocument[]
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    await this.checkClientAvailable();
    const resp = await this.client.getByType(type, {
      lang,
      pageSize: 100,
      page,
      filters,
      fetchLinks,
    });
    if (resp.results) resp.results = oldResults.concat(resp.results);
    if (resp.total_pages > page) return this.getByTypeRec(type, filters, lang, page + 1, fetchLinks, resp.results);
    return resp.results;
  }

  /**
   * Gets all documents of a certain type that have a content relationship to a certain document
   * @param type The type of document to get
   * @param field The name of the field to get, for fields in groups, use group.field format
   * @param id The ID of the linked document (the one that is in the field)
   */
  async getByContentRelationship(
    type: string,
    field: string,
    id: string,
    options: { transferCache?: boolean } = { transferCache: true }
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    const lang = this.languages[this.lang.current];
    if (options.transferCache) {
      const transferKey = [this.lang.current, type, field, id].join('_') + this.getTransferKeyExtra();
      return this.transferState.withStateTransfer(transferKey, () =>
        this.getByContentRelationshipRec(type, field, id, lang)
      );
    }
    return this.getByContentRelationshipRec(type, field, id, lang);
  }

  private async getByContentRelationshipRec(
    type: string,
    field: string,
    id: string,
    lang: string,
    page = 1,
    oldResults: PrismicDocument[] = []
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    await this.checkClientAvailable();
    const resp = await this.client.get({
      filters: [prismicFilter.at('document.type', type), prismicFilter.at(`my.${type}.${field}`, id)],
      lang,
      pageSize: 100,
      page,
    });
    if (resp.results) resp.results = oldResults.concat(resp.results);
    if (resp.total_pages > page) return this.getByContentRelationshipRec(type, field, id, lang, page + 1, resp.results);
    return resp.results;
  }

  /**
   * Gets a prismic document based on the value of a field
   */
  async getByFieldValue(
    type: string,
    field: string,
    value: string
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>> {
    await this.checkClientAvailable();

    const locale = this.languages[this.lang.current];
    const transferKey = locale + '_' + type + '_' + field + '_' + value + this.getTransferKeyExtra();

    const result = await this.transferState.withStateTransfer(transferKey, async () => {
      try {
        return await this.client.get({
          filters: [prismicFilter.at(`my.${type}.${field}`, value)],
          lang: locale,
        });
      } catch {
        return undefined;
      }
    });
    if (!result.results.length) return undefined;
    return result.results[0];
  }

  async fullTextSearch(location: string, value: string) {
    await this.checkClientAvailable();
    return this.client.get({
      filters: [prismicFilter.fulltext(location, value)],
      lang: this.languages[this.lang.current],
    });
  }

  private getTransferKeyExtra() {
    const isPreview = this.cookieService.check('io.prismic.preview');
    return isPreview ? '_preview' : '';
  }
}

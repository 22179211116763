<form [formGroup]="form">
  <lib-input-form-field
    [appearance]="'fill'"
    class="w-full"
    [theme]="'warn'"
    [autoFocus]="true"
    [label]="['support', 'search_query'] | translate"
    type="text"
    formControlName="search"
    [placeholder]="['support', 'search_query'] | translate"
    icon="fontawesome/search"
  >
  </lib-input-form-field>

  <app-results [results]="searchResults$ | async" (searchResultSelect)="onSearchResultSelect()"></app-results>
</form>

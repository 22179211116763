.link {
  &.text-primary path {
    fill: var(--color-primary);
  }
  &.text-featured path {
    fill: var(--color-featured);
  }
  &.text-gray-2 path {
    fill: var(--color-gray-2);
  }
}

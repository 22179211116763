import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { map, switchMap } from 'rxjs';
import { BreadcrumbsComponent } from 'breadcrumbs';
import { prismicToHtmlString } from 'prismic';
import { CategoryService } from '../category.service';
import { SupportArticle } from '../support-types';

@Component({
  selector: 'app-article-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, DynamicHooksComponent, BreadcrumbsComponent],
  templateUrl: './article-page.component.html',
  styleUrl: './article-page.component.scss',
})
export class ArticlePageComponent {
  private route = inject(ActivatedRoute);
  private categoryService = inject(CategoryService);
  public article$ = this.route.data.pipe(map(data => data.article as PrismicDocument<SupportArticle>));
  public articleContent$ = this.article$.pipe(map(article => prismicToHtmlString(article.data.content)));
  public categoryChain$ = this.article$.pipe(
    switchMap(article => {
      return this.categoryService.getCategoryChain(article.data.category?.id);
    })
  );
}

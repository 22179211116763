"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckoutService = void 0;
const Interfaces_1 = require("../Interfaces");
var CheckoutService;
(function (CheckoutService) {
  /**
   * @title YOL Service
   * @version 1.0.236
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL CHECKOUT MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.eshop = {
        /**
        * No description
        *
        * @tags eshop
        * @name EshopAddSign
        * @summary Add customer signature with sign category contract signature only
        * @request POST:/checkout/eshop/add-sign
        * @response `200` `DocumentResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_ADD_SIGNATUE",
             }`
        */
        eshopAddSign: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-sign`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name CreateSessionId
        * @summary Generate checkout session id for eshop
        * @request POST:/checkout/eshop/create-session-id
        * @response `200` `CheckoutResponse` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        createSessionId: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/create-session-id`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name CheckoutDetailsByIdV1
        * @summary V1 Get Checkout Details by session id for eshop
        * @request GET:/checkout/eshop/checkout-detail-v1/{checkoutSessionId}
        * @response `200` `CheckoutDetailsV1Resp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        checkoutDetailsByIdV1: (checkoutSessionId, params = {}) => this.http.request("GET", `${this.url}/checkout/eshop/checkout-detail-v1/${checkoutSessionId}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name CreateSessionIdV1
        * @summary Generate checkout session id for eshop
        * @request POST:/checkout/eshop/create-session-id-v1
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        createSessionIdV1: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/create-session-id-v1`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name AddCustomerDetails
        * @summary Add/Update Customer details for eshop
        * @request POST:/checkout/eshop/customer-details
        * @response `200` `CustomerDetailsResp` Ok
        * @response `400` `{
        error: "ERR_NO_DATA_TO_UPDATE_OR_NOT_ALLOWED" | "ERR_CUSTOMER_IS_UNDER_AGE",
             }`
        * @response `401` `{
        error: "ERR_CUSTOMER_FOUND_LOGIN_REQUIRED",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        addCustomerDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/customer-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name ConfirmCustomerDetails
        * @summary Confirm Customer details for eshop
        * @request POST:/checkout/eshop/confirm-customer-details
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_BILLING_ADDRESS_IS_MISSING",
             }`
        * @response `401` `{
        error: "ERR_CUSTOMER_FOUND_LOGIN_REQUIRED" | "ERR_EMAIL_MISSING",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `422` `{
        error: "ERR_SCHEMA_VALIDATION",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        confirmCustomerDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/confirm-customer-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name SelfcareAddAccountId
        * @summary Add Account Id for Selfcare
        * @request POST:/checkout/eshop/add-account-id
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_TOKEN_TYPE" | "ERR_PREPAID_MAX_REACHED" | "ERR_MISSING_DATA",
             }`
        * @response `401` `{
        error: "ERR_CUSTOMER_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        selfcareAddAccountId: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-account-id`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name UpdateAddressDetails
        * @summary Update address details
        * @request POST:/checkout/eshop/update-address-details
        * @response `200` `CheckoutResponse` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_CUSTOMER_DETAILS_EMPTY" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        updateAddressDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/update-address-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name ResetCustomerDetails
        * @summary reset customer address details
        * @request POST:/checkout/eshop/reset-customer-details
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        resetCustomerDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/reset-customer-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name SetPersonalId
        * @summary set personal id
        * @request POST:/checkout/eshop/existing-customer/set-pid
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        setPersonalId: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/existing-customer/set-pid`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name UpdateAddressDetailsV1
        * @summary Update address details
        * @request POST:/checkout/eshop/update-address-details-v1
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_MISSING_VALUE_ADDRESS_PROPERTIES",
             }`
        * @response `404` `{
        error: "ERR_CUSTOMER_DETAILS_EMPTY" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        updateAddressDetailsV1: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/update-address-details-v1`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name AddEmail
        * @summary Validate and store customer email
        * @request POST:/checkout/eshop/add-email
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_EMAIL",
             }`
        * @response `401` `{
        error: "ERR_EMAIL_EXISTS",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE" | "ERR_FROM_API",
             }`
        */
        addEmail: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-email`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name SelfcareAddAccountIdV1
        * @summary Add Account Id for Selfcare
        * @request POST:/checkout/eshop/add-account-id-v1
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_TOKEN_TYPE" | "ERR_PREPAID_MAX_REACHED" | "ERR_MISSING_DATA",
             }`
        * @response `401` `{
        error: "ERR_CUSTOMER_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        selfcareAddAccountIdV1: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-account-id-v1`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name GetUnsignedDocument
        * @summary Unsigned contract/POA
        * @request GET:/checkout/eshop/unsigned-document/{checkoutSessionId}/{category}
        * @response `200` `PdfResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_NO_DOCUMENT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getUnsignedDocument: (checkoutSessionId, category, params = {}) => this.http.request("GET", `${this.url}/checkout/eshop/unsigned-document/${checkoutSessionId}/${category}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name GetContract
        * @summary contract/POA
        * @request GET:/checkout/eshop/document/{checkoutSessionId}/{category}
        * @response `200` `PdfResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_NO_DOCUMENT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getContract: (checkoutSessionId, category, params = {}) => this.http.request("GET", `${this.url}/checkout/eshop/document/${checkoutSessionId}/${category}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name StartIdCheck
        * @summary Initiates Eshop ID check, returning IDC token.
        * @request POST:/checkout/eshop/start-id-check
        * @response `200` `StartIdCheckResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        startIdCheck: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/start-id-check`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name ConfirmPersonalIdDetails
        * @summary Confirm personal id document details for all flow
        * @request POST:/checkout/eshop/confirm-personal-id-details
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_SESSION_ID" | "ERR_INVALID_ID_TYPE_ID",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_DB_UPDATE_FAILED",
             }`
        */
        confirmPersonalIdDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/confirm-personal-id-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name AddLineCheckRef
        * @summary Update Line check refId
        against checkout session Id for eshop
        * @request POST:/checkout/eshop/add-linecheck-ref
        * @response `200` `LineCheckResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_WISHDATE_NOT_CORRECT",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_LINECHECK_REF_ID_NOT_FOUND" | "ERR_PRODUCT_NOT_AVAILABLE_IN_LINECHECK" | "ERR_ACCESS_PROVIDER_NOT_AVAILABLE",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE" | "ERR_FROM_LINECHECK_API",
             }`
        */
        addLineCheckRef: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-linecheck-ref`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name AddProduct
        * @summary Add Product to cart for eshop
        * @request POST:/checkout/eshop/add-product
        * @response `200` `BasketResponse` Ok
        * @response `400` `{
        error: "ERR_INVALID_LOCKING_PERIOD" | "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_PRODUCT_NOT_FOUND" | "ERR_PROMOTION_NOT_FOUND" | "ERR_PROMOTION_AVAILABLELOCKING_NOT_FOUND" | "ERR_PRODUCT_PLAN_OR_AVAILABLELOCKING_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        addProduct: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-product`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name UpdateSimType
        * @summary Select sim type for eshop
        * @request POST:/checkout/eshop/update-sim-type
        * @response `200` `UpdateSimResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_SESSION_ID" | "ERR_NO_DATA_TO_UPDATE_OR_NOT_ALLOWED" | "ERR_MISSING_MSISDN",
             }`
        * @response `404` `{
        error: "ERR_PRODUCT_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_API",
             }`
        */
        updateSimType: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/update-sim-type`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name EshopSubmitOrder
        * @summary ESHOP submit order client
        * @request POST:/checkout/eshop/submit-order
        * @response `200` `SubmitOrderResp` Ok
        * @response `400` `{
        error: "ERR_ORDER_ALREADY_IN_PROCESS" | "ERR_CHECKOUT_DETAILS_MISSING_DATA" | "ERR_SIGNED_DOCUMENT_EMPTY" | "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_CHECKOUT_DETAILS_EMPTY" | "ERR_ORDER_ALREADY_IN_PROCESS",
             }`
        * @response `422` `{
        error: "ERR_CHECKOUT_DETAILS_MISSING_DATA",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        eshopSubmitOrder: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/submit-order`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name AddTmxCheck
        * @summary Add tmx check for a checkout session id
        for eshop
        * @request POST:/checkout/eshop/add-tmx-check
        * @response `200` `TmxResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_WHEN_ADDING_TMX",
             }`
        */
        addTmxCheck: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/eshop/add-tmx-check`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.pos = {
        /**
        * No description
        *
        * @tags pos
        * @name PosAddSign
        * @summary Add customer signature with sign category [contract or poa signature] only
        * @request POST:/checkout/pos/add-sign
        * @response `200` `DocumentResp` Ok
        * @response `400` `{
        error: "ERR_DOCUMENT_MISSING",
             }`
        * @response `403` `{
        error: "ERR_CHANNEL_NOT_ALLOWED_TO_SEND_DOCUMENT",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_ADD_SIGNATUE",
             }`
        */
        posAddSign: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/add-sign`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name CreatePosSessionId
        * @summary Generate checkout session id for POS
        * @request POST:/checkout/pos/create-session-id
        * @secure bearerAuth
        * @response `200` `CheckoutResponse` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_TOKEN_TYPE",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        createPosSessionId: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/create-session-id`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name CreatePosSessionIdV1
        * @summary Generate checkout session id for POS
        * @request POST:/checkout/pos/create-session-id-v1
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `401` `{
        error: "ERR_INVALID_TOKEN_TYPE",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        createPosSessionIdV1: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/create-session-id-v1`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name AddPosLineCheckRef
        * @summary Update Line check refId
        against checkout session Id for pos
        * @request POST:/checkout/pos/add-linecheck-ref
        * @secure bearerAuth
        * @response `200` `LineCheckResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_PRODUCT_NOT_AVAILABLE_IN_LINECHECK" | "ERR_LINECHECK_REF_ID_NOT_FOUND" | "ERR_ACCESS_PROVIDER_NOT_AVAILABLE",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE" | "ERR_SYSTEM_ERROR" | "ERR_FROM_LINECHECK_API",
             }`
        */
        addPosLineCheckRef: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/add-linecheck-ref`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name AddCustomerDetails
        * @summary Add/Update Customer details for pos
        * @request POST:/checkout/pos/customer-details
        * @secure bearerAuth
        * @response `200` `CustomerDetailsResp` Ok
        * @response `400` `{
        error: "ERR_NO_DATA_TO_UPDATE_OR_NOT_ALLOWED" | "ERR_CUSTOMER_IS_UNDER_AGE",
             }`
        * @response `401` `{
        error: "ERR_CUSTOMER_FOUND_LOGIN_REQUIRED",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        addCustomerDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/customer-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name ConfirmCustomerDetails
        * @summary Confirm Customer details for pos
        * @request POST:/checkout/pos/confirm-customer-details
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_BILLING_ADDRESS_IS_MISSING",
             }`
        * @response `401` `{
        error: "ERR_CUSTOMER_FOUND_LOGIN_REQUIRED" | "ERR_EMAIL_MISSING",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `422` `{
        error: "ERR_SCHEMA_VALIDATION",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE",
             }`
        */
        confirmCustomerDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/confirm-customer-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name PosAddAccountId
        * @summary Add Account Id for POS
        * @request POST:/checkout/pos/add-account-id
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_TOKEN_TYPE" | "ERR_PREPAID_MAX_REACHED" | "ERR_MISSING_DATA",
             }`
        * @response `404` `{
        error: "ERR_CUSTOMER_NOT_FOUND" | "ERR_REF_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_API",
             }`
        */
        posAddAccountId: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/add-account-id`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name PosUpdateAddressDetails
        * @summary POS - Update address details
        * @request POST:/checkout/pos/update-address-details
        * @secure bearerAuth
        * @response `200` `CheckoutResponse` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_CUSTOMER_DETAILS_EMPTY",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        posUpdateAddressDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/update-address-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name ResetCustomerDetails
        * @summary reset customer details
        * @request POST:/checkout/pos/reset-customer-details
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        resetCustomerDetails: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/reset-customer-details`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name AddPosEmail
        * @summary POS - Validate and store customer email
        * @request POST:/checkout/pos/add-email
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_INVALID_EMAIL",
             }`
        * @response `401` `{
        error: "ERR_EMAIL_EXISTS",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_SERVICE" | "ERR_FROM_API",
             }`
        */
        addPosEmail: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/add-email`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name SetPosPersonalId
        * @summary set personal id
        * @request POST:/checkout/pos/existing-customer/set-pid
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_CUSTOMER_NOT_FOUND" | "ERR_REF_ID_NOT_FOUND" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        setPosPersonalId: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/existing-customer/set-pid`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name PosUpdateAddressDetailsV1
        * @summary POS - Update address details
        * @request POST:/checkout/pos/update-address-details-v1
        * @secure bearerAuth
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `400` `{
        error: "ERR_INVALID_ORIGIN" | "ERR_MISSING_VALUE_ADDRESS_PROPERTIES",
             }`
        * @response `404` `{
        error: "ERR_CUSTOMER_DETAILS_EMPTY",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        posUpdateAddressDetailsV1: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/update-address-details-v1`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name GetUnsignedDocument
        * @summary Unsigned contract/POA
        * @request GET:/checkout/pos/unsigned-document/{checkoutSessionId}/{category}
        * @secure bearerAuth
        * @response `200` `PdfResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_NO_DOCUMENT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getUnsignedDocument: (checkoutSessionId, category, params = {}) => this.http.request("GET", `${this.url}/checkout/pos/unsigned-document/${checkoutSessionId}/${category}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name GetContract
        * @summary contract/POA
        * @request GET:/checkout/pos/document/{checkoutSessionId}/{category}
        * @secure bearerAuth
        * @response `200` `PdfResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_NO_DOCUMENT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getContract: (checkoutSessionId, category, params = {}) => this.http.request("GET", `${this.url}/checkout/pos/document/${checkoutSessionId}/${category}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name SignStatus
        * @request POST:/checkout/pos/sign-status
        * @secure bearerAuth
        * @response `200` `SignStatusResp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_NO_DOCUMENT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        signStatus: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/sign-status`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name SendSignLink
        * @summary Send sign link
        * @request POST:/checkout/pos/send-sign-link
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_METHOD" | "ERR_NO_DOCUMENT_FOUND",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_API",
             }`
        */
        sendSignLink: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/send-sign-link`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name AddPosProduct
        * @summary Add Product to cart for pos
        * @request POST:/checkout/pos/add-product
        * @secure bearerAuth
        * @response `200` `BasketResponse` Ok
        * @response `400` `{
        error: "ERR_INVALID_LOCKING_PERIOD",
             }`
        * @response `404` `{
        error: "ERR_PRODUCT_NOT_FOUND" | "ERR_PROMOTION_NOT_FOUND" | "ERR_PROMOTION_AVAILABLELOCKING_NOT_FOUND" | "ERR_PRODUCT_PLAN_OR_AVAILABLELOCKING_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        addPosProduct: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/add-product`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name SubmitPosOrder
        * @summary POS submit order client
        * @request POST:/checkout/pos/submit-order
        * @secure bearerAuth
        * @response `200` `SubmitOrderResp` Ok
        * @response `400` `{
        error: "ERR_ORDER_ALREADY_IN_PROCESS" | "ERR_CHECKOUT_DETAILS_MISSING_DATA" | "ERR_SIGNED_DOCUMENT_EMPTY" | "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND" | "ERR_CHECKOUT_DETAILS_EMPTY" | "ERR_ORDER_ALREADY_IN_PROCESS",
             }`
        * @response `422` `{
        error: "ERR_CHECKOUT_DETAILS_MISSING_DATA",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        submitPosOrder: (data, params = {}) => this.http.request("POST", `${this.url}/checkout/pos/submit-order`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name CurrentStateById
        * @summary Get Checkout Details by session id for pos
        * @request GET:/checkout/current-state/{checkoutSessionId}
        * @response `200` `CheckStateResp` Ok
        * @response `404` `{
        error: "ERR_SCHEMA_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        currentStateById: (checkoutSessionId, params = {}) => this.http.request("GET", `${this.url}/checkout/current-state/${checkoutSessionId}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name ExpireSessionId
        * @summary To expire session id
        * @request GET:/checkout/sessionId-expire/{checkoutSessionId}
        * @response `200` `{
        checkoutSessionId: string,
             }` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        expireSessionId: (checkoutSessionId, params = {}) => this.http.request("GET", `${this.url}/checkout/sessionId-expire/${checkoutSessionId}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name CheckoutDetailsById
        * @summary Get Checkout Details by session id for eshop and Pos both
        * @request GET:/checkout/checkout-detail/{checkoutSessionId}
        * @response `200` `CheckoutDetailsV1Resp` Ok
        * @response `404` `{
        error: "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_FROM_SERVICE",
             }`
        */
        checkoutDetailsById: (checkoutSessionId, params = {}) => this.http.request("GET", `${this.url}/checkout/checkout-detail/${checkoutSessionId}`, {
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  CheckoutService.Api = Api;
})(CheckoutService = exports.CheckoutService || (exports.CheckoutService = {}));
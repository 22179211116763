"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelfcareBillsService = void 0;
var SelfcareBillsService;
(function (SelfcareBillsService) {
  /**
   * @title YOL Service
   * @version 1.0.4
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL SELFCARE BILLS MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags Public
        * @name GetOpenInvoices
        * @request GET:/selfcare-bills/open-invoices
        * @secure bearerAuth
        * @response `200` `OpenInvoicesResp` Ok
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getOpenInvoices: (params = {}) => this.http.request("GET", `${this.url}/selfcare-bills/open-invoices`, {
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  SelfcareBillsService.Api = Api;
})(SelfcareBillsService = exports.SelfcareBillsService || (exports.SelfcareBillsService = {}));
"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductCatalogService = void 0;
var ProductCatalogService;
(function (ProductCatalogService) {
  /**
   * @title YOL Service
   * @version 1.0.135
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL PRODUCT CATALOG SERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name GetCatalog
        * @summary Provides a list of online plans filtered by brand an code from the catalog
        * @request GET:/product-catalog/catalog
        * @response `200` `{
        plans: (CatalogResponse)[],
             }` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_DB_MISSING_ZIRA_CATALOG" | "ERR_DB_MISSING_RFE_CATALOG" | "ERR_NO_RECORD_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getCatalog: (query, params = {}) => this.http.request("GET", `${this.url}/product-catalog/catalog`, {
          params: query,
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name GetRatePlans
        * @summary Provides a list of all plans filtered by brand from the catalog
        * @request GET:/product-catalog/rateplans
        * @response `200` `{
        plans: (RatePlanResponse)[],
             }` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_DB_MISSING_ZIRA_CATALOG" | "ERR_DB_MISSING_RFE_CATALOG",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getRatePlans: (query, params = {}) => this.http.request("GET", `${this.url}/product-catalog/rateplans`, {
          params: query,
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name GetPromotionByCode
        * @summary Provides a single promotion filtered by code from the catalog
        * @request GET:/product-catalog/promotion/{code}
        * @response `200` `{
        promotions: (PromotionResponse)[],
             }` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_DB_MISSING_ZIRA_CATALOG" | "ERR_DB_MISSING_RFE_CATALOG",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getPromotionByCode: (code, params = {}) => this.http.request("GET", `${this.url}/product-catalog/promotion/${code}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name GetCountries
        * @request GET:/product-catalog/countries
        * @response `200` `CountriesResponse` Ok
        * @response `404` `{
        error: "ERR_DB_MISSING_COUNTRIES_DATA",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getCountries: (params = {}) => this.http.request("GET", `${this.url}/product-catalog/countries`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name GetNationality
        * @request GET:/product-catalog/nationality/{iso3Code}
        * @response `200` `NationalityResponse` Ok
        * @response `404` `{
        error: "ERR_NATIONALITY_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getNationality: (iso3Code, params = {}) => this.http.request("GET", `${this.url}/product-catalog/nationality/{iso3Code}`, {
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name PersonalIdTypes
        * @summary Provides a list personal id types
        * @request GET:/product-catalog/personal-id-types/{countryIso}/{paymentType}
        * @response `200` `(PersonalIdTypeResponse)[]` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        personalIdTypes: (countryIso, paymentType, params = {}) => this.http.request("GET", `${this.url}/product-catalog/personal-id-types/${countryIso}/${paymentType}`, {
          ...params
        })
      };
      this.pos = {
        /**
        * No description
        *
        * @tags pos
        * @name GetCatalog
        * @summary Provides a list of plans filtered by channel, brand an code from the catalog
        * @request GET:/product-catalog/pos/catalog
        * @secure bearerAuth
        * @response `200` `{
        plans: (CatalogResponse)[],
             }` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_DB_MISSING_ZIRA_CATALOG" | "ERR_DB_MISSING_RFE_CATALOG" | "ERR_NO_RECORD_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getCatalog: (query, params = {}) => this.http.request("GET", `${this.url}/product-catalog/pos/catalog`, {
          params: query,
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name GetRatePlans
        * @summary Provides a list of plans filtered by channel and brand from the catalog
        * @request GET:/product-catalog/pos/rateplans
        * @secure bearerAuth
        * @response `200` `{
        plans: (RatePlanResponse)[],
             }` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_DB_MISSING_ZIRA_CATALOG" | "ERR_DB_MISSING_RFE_CATALOG",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getRatePlans: (query, params = {}) => this.http.request("GET", `${this.url}/product-catalog/pos/rateplans`, {
          params: query,
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name GetPromotionByCode
        * @summary Provides a single promotion filtered by code from the catalog
        * @request GET:/product-catalog/pos/promotion/{code}
        * @secure bearerAuth
        * @response `200` `{
        promotions: (PromotionResponse)[],
             }` Ok
        * @response `403` `{
        error: "ERR_INVALID_ORIGIN",
             }`
        * @response `404` `{
        error: "ERR_DB_MISSING_ZIRA_CATALOG" | "ERR_DB_MISSING_RFE_CATALOG",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        getPromotionByCode: (code, params = {}) => this.http.request("GET", `${this.url}/product-catalog/pos/promotion/${code}`, {
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  ProductCatalogService.Api = Api;
})(ProductCatalogService = exports.ProductCatalogService || (exports.ProductCatalogService = {}));
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelfcareService } from '@yol-digital/ms-client';
import { format } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BrandService } from 'brand';
import { Promotion } from 'interfaces';
import { LanguageService } from 'language';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PCProduct } from 'product';
import { BrowserService, DATE_MS_FORMAT } from 'utils';
import {
  BaseAnalyticsModel,
  CustomFormEvent,
  ECommerceEvent,
  ECommerceItem,
  TrackingCookies,
  UserDataAnalytics,
  VirtualPageViewEvent,
} from './analytics.model';
import { PageView } from './analytics.product.model';

declare global {
  interface Window {
    dataLayer: object[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements BaseAnalyticsModel {
  private route = inject(ActivatedRoute);
  private lang = inject(LanguageService);
  private browserService = inject(BrowserService);
  private cookies = inject(CookieService);
  private brandService = inject(BrandService);
  private platformId = inject(PLATFORM_ID);
  private document = inject<Document>(DOCUMENT);
  private zone = inject(NgZone);
  previousUrl: string | undefined;
  user_data: UserDataAnalytics;
  customer_data: UserDataAnalytics;

  buildUserData(account: SelfcareService.AccountResp, visitorId: string): UserDataAnalytics {
    const user = account.ownerDetails;
    return {
      first_name: user.firstName,
      last_name: user.lastName,
      email_address: user.email,
      phone_number: user.contactNumber,
      country: account.ownerAddress.countryIso3Code,
      city: account.ownerAddress.city,
      postal_code: account.ownerAddress.zip,
      street_address: `${account.ownerAddress.street} ${account.ownerAddress.streetNumber}`,
      date_of_birth: format(new Date(user.dob), DATE_MS_FORMAT),
      external_id: visitorId,
    };
  }

  addOriginalLocation() {
    this.addToDataLayer({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    });
  }

  /**
   * Sends a custom event to the datalayer.
   * @param data
   */
  customEvent(data: object): void {
    const customData = {
      event: 'gtm visitor interaction',
      ...data,
    };
    this.addToDataLayer(customData);
  }

  customFormEvent(eventData: CustomFormEvent): void {
    const data = {
      event: 'gtm visitor interaction', // make sure this is in every event
      ...eventData,
    };
    this.addToDataLayer(data);
  }

  getTrackingCookies(orderTrackingId: string): Observable<TrackingCookies> {
    return this.route.queryParams.pipe(
      take(1),
      map(params => {
        let value: TrackingCookies;

        let gclid = false;
        let fbclid = false;
        let utm_medium: string;
        let utm_source: string;
        let utm_campaign: string;

        if (params['gclid']) gclid = params['gclid'];
        if (params['fbclid']) fbclid = params['fbclid'];
        if (params['utm_medium']) utm_medium = params['utm_medium'];
        if (params['utm_source']) utm_source = params['utm_source'];
        if (params['utm_campaign']) utm_campaign = params['utm_campaign'];

        value = {
          extraTracking: {
            _fbp: this.cookies.get('_fbp'),
            _fbc: this.cookies.get('_fbc'),
            _ga: this.cookies.get('_ga'),
            _gid: this.cookies.get('_gid'),
            orderTrackingId: orderTrackingId,
            gclid,
            fbclid,
            client_user_agent: navigator.userAgent,
            event_source_url: location.href,
          },
        };

        if (utm_medium && utm_source && utm_campaign) {
          value = {
            ...value,
            utmparams: {
              medium: utm_medium,
              source: utm_source,
              campaign: utm_campaign,
            },
          };
        }

        return value;
      })
    );
  }

  setUserData(visitorId: string, userDataAnalytics?: UserDataAnalytics) {
    if (!userDataAnalytics) {
      this.user_data = { external_id: visitorId };
      this.customer_data = { external_id: visitorId };
    } else {
      this.user_data = userDataAnalytics;
      this.customer_data = userDataAnalytics;
    }
  }

  pageView(page: PageView) {
    const data: VirtualPageViewEvent = {
      event: page.event ?? 'virtual page view',
      page_title: page.page_title ?? this.document.title,
      page_name: this.browserService.getPathWithoutLanguage(),
      page_type: page.page_type || (page.product ? 'product' : 'landing-page'),
      page_url: window.location.href,
      page_referrer: this.previousUrl || this.document.referrer,
      page_language: this.lang.current,
      viewing_mode: 'browser',
      user_status: page.account ? 'loggedin' : 'notLoggedin',
      visitor_id: page.visitorId || '',
    };

    if (page.product) {
      data.ecommerce = {
        currencyCode: 'CHF',
        items: this.getECommerceItems(page.product, null),
      };
    }

    if (!page.account && page.visitorId) {
      data.user_data = { external_id: page.visitorId };
      data.customer_data = { external_id: page.visitorId };
    }
    if (page.account) {
      data.user_data = page.userDataAnalytics;
      data.customer_data = page.userDataAnalytics;
    }

    if (data.user_data) this.user_data = data.user_data;
    if (data.customer_data) this.customer_data = data.customer_data;

    this.previousUrl = data.page_url;
    this.addToDataLayer(data);
  }

  addECommerceEvent(event: string, product: PCProduct, promotion: Promotion, orderId?: string, user_type?: string) {
    const eCommerceItems = this.getECommerceItems(product, promotion);

    const data: ECommerceEvent = {
      event,
      user_data: this.user_data,
      user_type,
      customer_data: this.customer_data,
      category: eCommerceItems[0].item_category3,
      variant: product.item_variant,
      ecommerce: {
        currency: 'CHF',
        items: eCommerceItems,
      },
    };

    if (orderId !== undefined) {
      data.transaction_id = orderId;
      data.value = eCommerceItems.map(item => item.price).reduce((p, c) => p + c);
      data.coupon = promotion?.code;
    }

    this.addToDataLayer(data);
  }

  private getECommerceItems(product: PCProduct, promotion: Promotion): ECommerceItem[] {
    const price = product.discountedPrice(promotion?.discountAmount);

    const items = [
      {
        item_name: product.name,
        item_id: product.id,
        price: Number(price),
        item_brand: this.brandService.brand,
        item_category: product.item_category,
        item_category2: product.item_category2,
        item_category3: product.item_category3,
        item_category4: product.item_category4,
        item_category5: product.item_category5,
        item_variant: product.item_variant,
        quantity: 1,
      },
    ];

    if (product.activation_fee) {
      items.push({
        item_name: 'activation and SIM card',
        item_id: 'activation and SIM card',
        price: product.activation_fee - (promotion?.activationFeeDiscount ?? 0),
        item_brand: this.brandService.brand,
        item_category: 'Activation fee',
        item_category2: 'Activation fee',
        item_category3: 'Activation fee',
        item_category4: 'Activation fee',
        item_category5: 'Activation fee',
        item_variant: 'service fees',
        quantity: 1,
      });
    }

    return items;
  }

  addToDataLayer(data: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
      });
    }
  }

  public alreadyRegisteredOnDataLayer(value: string, field?: string) {
    const dataLayerField = field ?? 'interaction_name';
    if (isPlatformBrowser(this.platformId)) {
      return this.zone.runOutsideAngular(() => {
        window.dataLayer = window.dataLayer || [];
        // Avoid event being called multiple times while navigating inside the app
        const eventFound = window.dataLayer.find((item: { [key: string]: string }) => item[dataLayerField] === value);
        return eventFound ? true : false;
      });
    }
    return true;
  }
}

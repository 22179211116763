"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MsisdnSearchService = void 0;
const Interfaces_1 = require("../Interfaces");
var MsisdnSearchService;
(function (MsisdnSearchService) {
  /**
   * @title YOL Service
   * @version 1.0.88
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL MSISDN SEARCH MICROSERVICE
   */
  class Api {
    constructor(url, http) {
      this.eshop = {
        /**
        * No description
        *
        * @tags eshop
        * @name AvailableNumbers
        * @summary Msisdn lookup
        * @request POST:/msisdn-search/new-msisdn/available-numbers
        * @response `200` `MsisdnSearchResp` Ok
        * @response `400` `{
        error: "ERR_WRONG_PRODUCT_SPEC_FAMILY" | "ERR_INVALID_ORIGIN" | "ERR_PRODUCT_NOT_FOUND_IN_SESSION_ID" | "ERR_INVALID_SESSION_ID",
             }`
        * @response `404` `{
        error: "ERR_NUMBERS_NOT_FOUND" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `408` `{
        error: "ERR_CONNECTION_TIMEOUT",
             }`
        * @response `500` `{
        error: "ERR_CHECKOUT_API_SERVICE" | "ERR_FROM_CDRATOR_SOAP" | "ERR_SYSTEM_ERROR",
             }`
        */
        availableNumbers: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/new-msisdn/available-numbers`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * @description *
        *
        * @tags eshop
        * @name Confirm
        * @summary Confirm msisdn number
        * @request POST:/msisdn-search/new-msisdn/confirm
        * @response `204` `void` No content
        * @response `404` `{
        error: "ERR_INVALID_NEW_MSISDN" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_PHONE_NUMBER_DETAIL",
             }`
        */
        confirm: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/new-msisdn/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name Validate
        * @summary Vaidate porting number
        * @request POST:/msisdn-search/port/validate
        * @response `200` `PortValidateResp` Ok
        * @response `400` `{
        error: "ERR_PORTING_ALREADY_IN_PROGRESS" | "ERR_INVALID_NUMBER" | "ERR_INVALID_PORT_NUMBER" | "ERR_CANNOT_PORT_FROM_SAME_BRAND",
             }`
        * @response `404` `{
        error: "ERR_PROVIDER_INFO_NOT_FOUND" | "ERR_SESSION_ID_NOT_FOUND" | "ERR_PROVIDER_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_CHECKOUT_API_SERVICE" | "ERR_SYSTEM_ERROR",
             }`
        */
        validate: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/port/validate`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name PortConfirm
        * @summary confirm porting and its update details in checkout service
        * @request POST:/msisdn-search/port/confirm
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_ACTIVATION_MODE" | "ERR_INVALID_SESSION_ID",
             }`
        * @response `404` `{
        error: "ERR_REF_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_PHONE_NUMBER_DETAIL",
             }`
        */
        portConfirm: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/port/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags eshop
        * @name PortingProviderList
        * @summary provider list of all the porting providers
        * @request GET:/msisdn-search/port/provider-list
        * @response `200` `(ProvidersResp)[]` Ok
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        portingProviderList: (params = {}) => this.http.request("GET", `${this.url}/msisdn-search/port/provider-list`, {
          ...params
        })
      };
      this.pos = {
        /**
        * No description
        *
        * @tags pos
        * @name NewMsisdnLookup
        * @summary Msisdn lookup
        * @request POST:/msisdn-search/pos/new-msisdn/find
        * @secure bearerAuth
        * @response `200` `MsisdnSearchResp` Ok
        * @response `400` `{
        error: "ERR_WRONG_PRODUCT_SPEC_FAMILY" | "ERR_INVALID_ORIGIN" | "ERR_PRODUCT_NOT_FOUND_IN_SESSION_ID" | "ERR_INVALID_SESSION_ID",
             }`
        * @response `404` `{
        error: "ERR_NUMBERS_NOT_FOUND" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `408` `{
        error: "ERR_CONNECTION_TIMEOUT",
             }`
        * @response `500` `{
        error: "ERR_CHECKOUT_API_SERVICE" | "ERR_FROM_CDRATOR_SOAP" | "ERR_SYSTEM_ERROR",
             }`
        */
        newMsisdnLookup: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/pos/new-msisdn/find`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * @description *
        *
        * @tags pos
        * @name Confirm
        * @summary Confirm msisdn number
        * @request POST:/msisdn-search/pos/new-msisdn/confirm
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `404` `{
        error: "ERR_INVALID_NEW_MSISDN" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_PHONE_NUMBER_DETAIL",
             }`
        */
        confirm: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/pos/new-msisdn/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name PortValidate
        * @summary Vaidate porting number
        * @request POST:/msisdn-search/pos/port/validate
        * @secure bearerAuth
        * @response `200` `PortValidateResp` Ok
        * @response `400` `{
        error: "ERR_PORTING_ALREADY_IN_PROGRESS" | "ERR_INVALID_NUMBER",
             }`
        * @response `404` `{
        error: "ERR_PROVIDER_INFO_NOT_FOUND" | "ERR_SESSION_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_CHECKOUT_API_SERVICE",
             }`
        */
        portValidate: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/pos/port/validate`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name PortConfirm
        * @summary confirm porting and its update details in checkout service
        * @request POST:/msisdn-search/pos/port/confirm
        * @secure bearerAuth
        * @response `204` `void` No content
        * @response `400` `{
        error: "ERR_INVALID_ACTIVATION_MODE" | "ERR_INVALID_SESSION_ID",
             }`
        * @response `404` `{
        error: "ERR_REF_ID_NOT_FOUND",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_UPDATE_PHONE_NUMBER_DETAIL",
             }`
        */
        portConfirm: (data, params = {}) => this.http.request("POST", `${this.url}/msisdn-search/pos/port/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags pos
        * @name PortingProviderList
        * @summary provider list of all the porting providers
        * @request GET:/msisdn-search/pos/port/provider-list
        * @secure bearerAuth
        * @response `200` `(ProvidersResp)[]` Ok
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR",
             }`
        */
        portingProviderList: (params = {}) => this.http.request("GET", `${this.url}/msisdn-search/pos/port/provider-list`, {
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  MsisdnSearchService.Api = Api;
})(MsisdnSearchService = exports.MsisdnSearchService || (exports.MsisdnSearchService = {}));
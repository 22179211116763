"use strict";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PosAdminService = void 0;
const Interfaces_1 = require("../Interfaces");
var PosAdminService;
(function (PosAdminService) {
  /**
   * @title YOL Service
   * @version 1.0.5
   * @license MIT
   * @baseUrl /
   * @contact YOL Digital <digital-yol@sunrise.net>
   *
   * YOL POS ADMIN SERVICE
   */
  class Api {
    constructor(url, http) {
      this.public = {
        /**
        * No description
        *
        * @tags public
        * @name UpdateStart
        * @request POST:/pos-admin/update/phone-number/start
        * @secure bearerAuth
        * @response `200` `UpdatePhoneNumberStartResp` Ok
        * @response `400` `{
        error: "ERR_PHONE_EXCEED_LIMIT" | "ERR_INVALID_METHOD",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_API",
             }`
        */
        updateStart: (data, params = {}) => this.http.request("POST", `${this.url}/pos-admin/update/phone-number/start`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        }),
        /**
        * No description
        *
        * @tags public
        * @name Confirm
        * @request POST:/pos-admin/update/phone-number/confirm
        * @secure bearerAuth
        * @response `200` `UpdatePhoneNumberConfirmResp` Ok
        * @response `400` `{
        error: "ERR_INVALID_REFID" | "ERR_INVALID_CODE",
             }`
        * @response `429` `{
        error: "ERR_MAX_REACHED",
             }`
        * @response `500` `{
        error: "ERR_SYSTEM_ERROR" | "ERR_FROM_API" | "ERR_FROM_CRM",
             }`
        */
        confirm: (data, params = {}) => this.http.request("POST", `${this.url}/pos-admin/update/phone-number/confirm`, {
          body: data,
          headers: {
            "Content-Type": Interfaces_1.ContentType.Json
          },
          ...params
        })
      };
      this.http = http;
      this.url = url;
    }
  }
  PosAdminService.Api = Api;
})(PosAdminService = exports.PosAdminService || (exports.PosAdminService = {}));